import { ErrorModal, TreeNode, TreePickerControlV2 } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Location } from "../models/Location";
import { getLocations } from "../repositories/LocationRepository";
import { useHookLocations } from "./hooks/LocationTreePicker";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
  margin?: boolean;
};

const useLocations = (
  locationId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Location[], Error>({
    queryKey: ["locations", locationId],
    queryFn: () => getLocations(locationId),
    onError: onError,
  });
};

export const LocationSelector = ({
  label,
  value,
  onChange,
  disabled,
  margin = false
}: Props) => {
  const [error, setError] = useState("");
  const [locationsTree, setLocationsTree] = useState<TreeNode>();

  const { data: locations, isLoading } = useLocations(undefined, (error) =>
    setError(error.message)
  );

  const { loadFilterLocations } = useHookLocations({
    locations: locations,
    setLocationsTree: setLocationsTree,
  });

  useEffect(() => {
    if (locations && locations?.length >= 0) loadFilterLocations();
  }, [locations]);

  return (
    <>
      {isLoading || !locationsTree ? (
        <TextField
          label={label}
          disabled
          variant="outlined"
          fullWidth
          size="small"
          InputProps={{
            endAdornment: <CircularProgress color="inherit" size={20} />,
          }}
          InputLabelProps={{
            style: { color: "rgb(0, 0, 0, 0.6" },
          }}
        />
      ) : (
        <div style={{ marginTop: margin ? -8 : 0 }}>
          <TreePickerControlV2
            inputTitle={label}
            title="Parent"
            dataSource={locationsTree!}
            value={value || 0}
            onSelect={(value) => onChange(value)}
            selectBranch={true}
            selectActive={false}
            selectInternal={false}
            disabled={disabled}
          />
        </div>
      )}
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading locations"
      />
    </>
  );
};
