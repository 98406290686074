export interface Arbor {
	PartId: number | null;
	PartName: string;
	RFIDTag: string | null;
	LocationName: string | null;
	LocationId: number | null;
	IsActive: boolean | null;
}

export const ARBOR_INITIAL_VALUES: Arbor = {
	PartId: null,
	PartName: '',
	RFIDTag: '',
	LocationName: '',
	LocationId: null,
	IsActive: true
};