import { ErrorModal } from "@dexteel/mesf-core";
import { Grid, IconButton, TextField } from "@material-ui/core";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { useQuery } from "@tanstack/react-query";
import { ColDef, GridApi, SelectionChangedEvent } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { isNil } from "lodash-es";
import { useEffect, useState } from "react";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { SelectedPassShapes } from "../../../../models/BarrelPassShape";
import { CatalogPassShape } from "../../../../models/CatalogPassShape";
import { getPassShapesV2 } from "../../../../repositories/CatalogPassShapeRepository";
import { useGridStyles } from "../../../../styles/gridStyles";
import { CreatePassShape } from "./CreatePassShape";

const CreateNewPassShape = ({ showPassShapesModal }: any) => (
  <div
    style={{
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "30px",
      display: "flex",
      alignItems: "center",
    }}
  >
    <IconButton
      onClick={showPassShapesModal}
      color="primary"
      style={{ position: "absolute" }}
      title="New pass shape"
    >
      <PlaylistAddIcon style={{ width: 30, height: 30 }} />
    </IconButton>
  </div>
);

const usePassShapes = (
  passShapeId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<CatalogPassShape[], Error>({
    queryKey: ["passShapes", passShapeId],
    queryFn: () => getPassShapesV2(passShapeId),
    onError: onError,
  });
};

type Props = {
  onSelectionChanged: Function;
  disabled?: boolean;
  shapesOfBarrel: CatalogPassShape[];
};

export const PassShapesGrid = ({
  onSelectionChanged,
  disabled = false,
  shapesOfBarrel,
}: Props) => {
  const classes = useGridStyles();

  const [filterInput, setFilterInput] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { data: passShapes, isLoading } = usePassShapes(undefined, (error) =>
    setError(error.message)
  );

  const onAgGridSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedData: SelectedPassShapes[] = selectedRows.map(
      (node: any) => ({
        id: node.PassShapeId,
        position: node?.position || 0,
      })
    );
    onSelectionChanged(selectedData);
  };

  const handlerFilter = (e: any) => {
    setFilterInput(e.target.value);
  };

  const columnDefs: ColDef[] = [
    {
      field: "PassShapeName",
      headerName: "Pass Shapes",
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      flex: 1,
      cellStyle: {
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "-",
      headerName: "",
      maxWidth: 50,
      headerComponent: CreateNewPassShape,
      headerComponentParams: {
        showPassShapesModal: () => setShow(true),
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, shapesOfBarrel, gridApi]);

  useEffect(() => {
    gridApi?.setQuickFilter(filterInput.trim());
  }, [filterInput, gridApi]);

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        node.setSelected(
          !!node.data &&
            !isNil(
              shapesOfBarrel.find(
                (p) =>
                  p.PassShapeId === (node.data as CatalogPassShape).PassShapeId
              )
            )
        );
      });
    }
  }, [shapesOfBarrel, gridApi, passShapes]);

  return (
    <>
      <div className={classes.root} style={{ width: "100%" }}>
        <Grid container md={12} xs={12} spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={filterInput}
              onChange={handlerFilter}
              margin="dense"
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <div
              style={{ height: "41vh", width: "100%" }}
              className="ag-theme-alpine"
            >
              <AgGridReact<CatalogPassShape>
                rowData={passShapes || []}
                columnDefs={columnDefs}
                rowHeight={30}
                headerHeight={30}
                animateRows
                rowSelection="multiple"
                rowMultiSelectWithClick
                suppressContextMenu
                loadingOverlayComponent={CenteredLazyLoading}
                onSelectionChanged={onAgGridSelectionChanged}
                onGridReady={(params) => setGridApi(params.api)}
                getRowId={(params) => String(params.data.PassShapeId)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <ErrorModal error={error} onHide={() => setError("")} />
      <CreatePassShape show={show} onHide={() => setShow(false)} />
    </>
  );
};
