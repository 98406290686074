import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Cartridge } from "../models/Cartridge";
import { getCartridges } from "../repositories/CartridgeRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

const useCartridges = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<Cartridge[], Error>({
    queryKey: ["cartridges", partId],
    queryFn: () => getCartridges(partId),
    onError: onError,
  });
};

export const CartridgeSelector = ({
  label,
  value,
  onChange,
  disabled,
}: Props) => {
  const [error, setError] = useState("");

  const { data: cartridges, isLoading } = useCartridges(undefined, (error) =>
    setError(error.message)
  );

  const selectedValue = cartridges?.find(
    (cartridge) => cartridge.PartId === value
  );

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={cartridges || []}
        getOptionLabel={(option) => option.PartName}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedValue}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading cartridges"
      />
    </>
  );
};
