import { ErrorModal } from "@dexteel/mesf-core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { Arbor } from "../../../models/parts/Arbor";
import { getArbors } from "../../../repositories/parts/ArborRepository";
import { usePageStyles } from "../../../styles/pageStyles";
import { TableArbor } from "./components/TableArbor";

export const useArbors = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<Arbor[], Error>({
    queryKey: ["arbors", partId],
    queryFn: () => getArbors(partId),
    onError: onError,
  });
};

export const ArborPage = () => {
  const classes = usePageStyles();

  const [error, setError] = useState("");

  const { data, isLoading } = useArbors(undefined, (error) =>
    setError(error.message)
  );

  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12} xs={12} style={{ margin: 0 }}>
          <Paper elevation={1} className={classes.paper}>
            <TableArbor isLoading={isLoading} />
          </Paper>
        </Grid>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
