import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { CatalogCartridgeChock } from "../../models/parts/CatalogCartridgeChock";

export const getCartridgeChocks = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetCartridgeChocks]", parameters);
};
export const upsertCartridgeChock = async (cartridgeChock: CatalogCartridgeChock) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: cartridgeChock.PartId },
		{ name: "PartName", value: cartridgeChock.PartName },
	];

	return await apiService.callV2("[M2].[UpsertCartridgeChock]", parameters);
};

export const deleteCartridgeChock = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteCartridgeChock]", parameters);
};