import { ErrorModal } from "@dexteel/mesf-core";
import { Grid } from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import { isNil } from "lodash-es";
import { useEffect, useState } from "react";
import { BarrelPassShape } from "../../../../models/BarrelPassShape";
import { CatalogBarrel } from "../../../../models/CatalogBarrel";
import { deleteBarrelPassShape } from "../../../../repositories/BarrelPassShapeRepository";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { CatalogPassShape } from "../../../../models/CatalogPassShape";
import { getBarrelPassShapes } from "../../../../repositories/BarrelPassShapeRepository";
import { SetPassShapes } from "../pass-shapes/SetPassShapes";
import { useBarrelPassShapeData } from "./hooks/useBarrelPassShapeData";

const useBarrelPassShapes = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<BarrelPassShape[], Error>({
    queryKey: ["barrelPassShapes", partId],
    queryFn: () => getBarrelPassShapes(partId),
    onError: onError,
  });
};

type Props = {
  disabled?: boolean;
  shapesOfBarrel: BarrelPassShape[];
  barrel: CatalogBarrel | null;
};

export const BarrelPassShapesGrid = ({
  disabled = false,
  shapesOfBarrel,
  barrel,
}: Props) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  // Use react-query
  const { data: passShapes, isLoading } = useBarrelPassShapes(
    undefined,
    (error) => setError(error.message)
  );
  const queryClient = useQueryClient();
  const mutation = useMutation(deleteBarrelPassShape, {
    onSuccess: () => {
      queryClient.invalidateQueries(["barrelPassShapes"]);
    },
    onError: (error: Error) => {
      setError(error.message);
    },
  });

  const { columnDefs, statusBar } = useBarrelPassShapeData({
    mutation: mutation,
    barrel: barrel,
    setShow: setShow,
  });

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, shapesOfBarrel, gridApi]);

  useEffect(() => {
    if (gridApi) {
      gridApi.forEachNode((node) => {
        node.setSelected(
          !!node.data &&
            !isNil(
              shapesOfBarrel.find(
                (p) =>
                  p.PassShapeId === (node.data as CatalogPassShape).PassShapeId
              )
            )
        );
      });
    }
  }, [shapesOfBarrel, gridApi]);

  return (
    <>
      <Grid item md={12} xs={12}>
        <div
          style={{ height: "30vh", width: "100%" }}
          className="ag-theme-alpine"
        >
          <AgGridReact<BarrelPassShape>
            rowData={passShapes || []}
            columnDefs={columnDefs}
            rowHeight={28}
            headerHeight={31}
            animateRows
            suppressRowClickSelection
            suppressContextMenu
            loadingOverlayComponent={CenteredLazyLoading}
            onGridReady={(params) => setGridApi(params.api)}
            getRowId={(params) => String(params.data.PassShapeId)}
            statusBar={statusBar}
          />
        </div>
      </Grid>
      <ErrorModal error={error} onHide={() => setError("")} />
      <SetPassShapes
        show={show}
        onHide={() => setShow(false)}
        barrel={barrel}
      />
    </>
  );
};
