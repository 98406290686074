import { ColDef, ICellRendererParams } from "ag-grid-enterprise";
import { GridButtons } from "../../../../../../controls/buttons/GridButtons";
import { Arbor } from "../../../../models/parts/Arbor";
import { useArbors } from "../ArborPage";
import { useQuery } from "@tanstack/react-query";

type Props = {
  setArborId: (id: number) => void;
  setShowModal: (modal: "create" | "update" | "delete" | "") => void;
  showContextMenu: Function;
};
export const useTableData = ({
  setArborId,
  setShowModal,
  showContextMenu,
}: Props) => {
  const { data: arbors } = useArbors(undefined, () => {});
  //const { data: arbors } = useQuery<Arbor, Error>(["arbors", undefined], {enabled: false});

  const rows: Arbor[] =
    arbors?.map((arbor: Arbor) => ({
      ...arbor,
    })) || [];

  const cellStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const columnDefs: ColDef[] = [
    {
      field: "PartName",
      headerName: "Name",
      minWidth: 20,
      flex: 1,
      cellStyle: cellStyle,
    },
    {
      cellRenderer: GridButtons,
      cellRendererParams: (params: ICellRendererParams) => ({
        setId: setArborId,
        id: (params.data as Arbor).PartId,
        setShowModal: setShowModal,
        showContextMenu: () =>
          showContextMenu(event, params.data, "arborContext"),
      }),
      wrapText: false,
      autoHeight: false,
      wrapHeaderText: false,
      headerName: "",
      sortable: false,
      flex: 1,
      minWidth: 200,
      filter: false,
      autoHeaderHeight: true,
    },
  ];
  return { rows, columnDefs };
};
