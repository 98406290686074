import {
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { ErrorModal } from "@dexteel/mesf-core";
import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";
import { BarrelPassShape } from "../../../models/BarrelPassShape";
import {
  CATALOG_BARREL_INITIAL_VALUES,
  CatalogBarrel,
} from "../../../models/CatalogBarrel";
import { CATALOG_GUIDE_INITIAL_VALUES } from "../../../models/parts/CatalogGuide";
import {
  deleteBarrel,
  upsertBarrel,
} from "../../../repositories/CatalogBarrelRepository";
import { BarrelPassShapesGrid } from "./barrel-pass-shapes/BarrelPassShapesGrid";

const useStyles = makeStyles(() => ({
  errorLabel: {
    fontSize: 12,
    color: "#F44336",
  },
  infoLabel: {
    fontSize: 12,
    color: "#757575",
  },
}));

type Props = {
  barrel: CatalogBarrel | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};

export const BarrelDetails = ({ barrel, modal, onHide }: Props) => {
  const classes = useStyles();

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const [shapesOfBarrel, setShapesOfBarrel] = useState<BarrelPassShape[]>([]);

  const succesfullyMessage = `The barrel was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit, getValues } =
    useForm<CatalogBarrel>({
      defaultValues: CATALOG_GUIDE_INITIAL_VALUES,
      mode: "onChange",
    });

  const onSubmit: SubmitHandler<CatalogBarrel> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const barrelResp = await deleteBarrel(data.BarrelId);
      if (barrelResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(barrelResp.message);
      }
    } else {
      const barrelResp = await upsertBarrel(data);
      if (barrelResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(barrelResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    reset(CATALOG_BARREL_INITIAL_VALUES);

    setIsSubmitLoading(false);
    if (barrel) {
      setValue("BarrelId", barrel?.BarrelId);
      setValue("BarrelName", barrel?.BarrelName ?? "");
      setValue("Comments", barrel?.Comments ?? "");
    } else {
      setShapesOfBarrel([]);
    }
  }, [modal, barrel]);

  return (
    <>
      {barrel === null && modal !== "create" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <Typography>Select a barrel or create a new</Typography>
        </div>
      ) : (
        <>
          {isLoading ? (
            <CenteredLazyLoading />
          ) : (
            <form id="upsert-barrel-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Controller
                    name={"BarrelName"}
                    control={control}
                    rules={{ required: "Name is required" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <FormControl error={!!error} fullWidth>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          size="small"
                          value={value}
                          onChange={onChange}
                        />
                        {error && (
                          <FormHelperText>{error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                {!isLoading && (
                  <BarrelPassShapesGrid
                    disabled={modal === "delete"}
                    shapesOfBarrel={shapesOfBarrel}
                    barrel={barrel}
                  />
                )}
                <Grid item md={12} sm={12} style={{ marginBottom: 10 }}>
                  <Controller
                    name="Comments"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Comments"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={field?.value}
                            maxRows={5}
                            minRows={2}
                            multiline
                            autoComplete="off"
                            disabled={modal === "delete"}
                            onChange={(event) => {
                              if (event.target.value.length <= 200) {
                                field.onChange(event.target.value);
                              }
                            }}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                        {(getValues("Comments")?.length as number) !== 200 && (
                          <span
                            className={classes.infoLabel}
                            style={{ marginLeft: 5 }}
                          >
                            {`${getValues("Comments")?.length ?? 0}/200 max.`}
                          </span>
                        )}
                        {(getValues("Comments")?.length as number) == 200 && (
                          <span
                            className={classes.infoLabel}
                            style={{ marginLeft: 5 }}
                          >
                            Max. 200
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{ margin: "0px 15px" }}
                >
                  <FooterModalButtons
                    isSubmitLoading={isSubmitLoading}
                    onHide={() => onHide(false)}
                    buttonLabel={modal === "delete" ? "Delete" : "Save"}
                    buttonColor={modal === "delete" ? "secondary" : "primary"}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </>
      )}
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal error={error} onHide={() => setError("")} />
    </>
  );
};
