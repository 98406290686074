import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";
import { SelectedPassShapes } from "../../../../models/BarrelPassShape";
import {
  CATALOG_BARREL_INITIAL_VALUES,
  CatalogBarrel,
} from "../../../../models/CatalogBarrel";
import { CatalogPassShape } from "../../../../models/CatalogPassShape";
import {
  getPassShapesByBarrel,
  setBarrelPassShapes,
} from "../../../../repositories/BarrelPassShapeRepository";
import { PassShapesGrid } from "./PassShapesGrid";

type Props = {
  barrel: CatalogBarrel | null;
  show: boolean;
  onHide: (shouldUpdate: boolean) => void;
};

export const SetPassShapes = ({ barrel, show, onHide }: Props) => {
  const [shapesOfBarrel, setShapesOfBarrel] = useState<
    CatalogPassShape[] | null
  >(null);
  const [shapesSelected, setShapesSelected] = useState<SelectedPassShapes[]>(
    []
  );

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const [error, setError] = useState<string>("");

  const { handleSubmit } = useForm<CatalogBarrel>({
    defaultValues: CATALOG_BARREL_INITIAL_VALUES,
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({
      barrelId,
      passShapesInfo,
    }: {
      barrelId: number;
      passShapesInfo: SelectedPassShapes[];
    }) => setBarrelPassShapes(barrelId, passShapesInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["barrelPassShapes"]);
        setMessage("The pass shapes of the barrel were edited successfully");
        onHide(true);
      },
      onError: (error: Error) => {
        setError(error.message);
      },
      onSettled: () => setIsSubmitLoading(false),
    }
  );

  const onSubmit: SubmitHandler<CatalogBarrel> = async () => {
    setIsSubmitLoading(true);
    mutation.mutate({
      barrelId: barrel?.BarrelId!,
      passShapesInfo: shapesSelected,
    });
  };

  useEffect(() => {
    if (show) {
      setIsSubmitLoading(false);
      (async () => {
        setIsLoading(true);
        if (barrel) {
          const resp = await getPassShapesByBarrel(barrel.BarrelId);
          if (resp.ok) setShapesOfBarrel(get(resp, "data.tables[0].rows", []));
          else setError(resp.message);
        }
        setIsLoading(false);
      })();
    } else {
      setShapesOfBarrel([]);
      setShapesSelected([]);
    }
  }, [show]);

  return (
    <>
      <MesfModal
        title="SET PASS SHAPES"
        open={show}
        handleClose={() => onHide(false)}
        id="set-pass-shapes-modal"
        maxWidth="lg"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <MesfModal.Content style={{ padding: "0 30px 15px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!error && !isLoading && (
              <PassShapesGrid
                onSelectionChanged={setShapesSelected}
                shapesOfBarrel={shapesOfBarrel || []}
              />
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            {!isLoading && (
              <FooterModalButtons
                isSubmitLoading={isSubmitLoading}
                onHide={onHide}
              />
            )}
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar message={message} onHide={() => setMessage("")} />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error Editing Barrel"
      />
    </>
  );
};
