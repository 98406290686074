import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Arbor } from "../../models/parts/Arbor";

export const getArbors = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetArbors]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Arbor[];
	}
	else {
		throw new Error(resp.message || "Error fetching arbors");
	}
};
export const upsertArbor = async (arbor: Arbor) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: arbor.PartId },
		{ name: "PartName", value: arbor.PartName }
	];

	return await apiService.callV2("[M2].[UpsertArbors]", parameters);
};

export const deleteArbor = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteArbor]", parameters);
};