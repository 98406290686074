import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { CartridgeChock } from "../models/CartridgeChock";
import { getCartridgeChocks } from "../repositories/CartridgeChockRepository";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

const useCartridgeChocks = (
  partId?: number,
  onError?: (error: Error) => void
) => {
  return useQuery<CartridgeChock[], Error>({
    queryKey: ["cartridgeChocks", partId],
    queryFn: () => getCartridgeChocks(partId),
    onError: onError,
  });
};

export const CartridgeChockSelector = ({
  label,
  value,
  onChange,
  disabled,
}: Props) => {
  const [error, setError] = useState("");

  const { data: cartridgeChocks, isLoading } = useCartridgeChocks(
    undefined,
    (error) => setError(error.message)
  );

  const selectedChock =
    cartridgeChocks?.find((chock) => chock.PartId === value) || null;

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={cartridgeChocks || []}
        getOptionLabel={(option) => option.PartName}
        getOptionSelected={(option, value) => option.PartId === value.PartId}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        value={selectedChock}
        onChange={(event, newValue) => {
          onChange(newValue?.PartId);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading cartridges"
      />
    </>
  );
};