import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { Autocomplete } from "@material-ui/lab";
import { DonutSelector } from "../../../../../../shared/selectors";
import {
  CATALOG_ROLL_INITIAL_VALUES,
  CatalogRoll,
} from "../../../../models/parts/CatalogRoll";
import {
  deleteRoll,
  getRolls,
  upsertRoll,
} from "../../../../repositories/parts/CatalogRollRepository";
import { PartFields } from "../../PartFields";

type Props = {
  RollId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ RollId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The roll was ${modal}d succesfully`;
  const optionsStatusCode = [
    { Name: "Ready" },
    { Name: "Installed" },
    { Name: "Damaged" },
  ];
  const optionsTypeCode = [{ Name: "Edger" }, { Name: "Universal" }];

  const { control, setValue, reset, handleSubmit } = useForm<CatalogRoll>({
    defaultValues: CATALOG_ROLL_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogRoll> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const rollResp = await deleteRoll(data.PartId);
      if (rollResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(rollResp.message);
      }
    } else {
      const rollResp = await upsertRoll(data);
      if (rollResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(rollResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  const rollTypeCode = useWatch({
    control,
    name: "RollTypeCode",
  });

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      searchDonuts();
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getRolls(RollId);
          if (response.ok) {
            const roll = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogRoll;
            if (roll) {
              setValue("PartId", roll?.PartId);
              setValue("PartName", roll?.PartName);
              setValue("RFIDTag", roll?.RFIDTag);
              setValue("LocationName", roll?.LocationName);
              setValue("DonutPartId", roll?.DonutPartId);
              setValue("Diameter", roll?.Diameter);
              setValue("RollStatusCode", roll?.RollStatusCode);
              setValue("RollTypeCode", roll?.RollTypeCode);
            }
          } else {
            setError(response.message!);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} ROLL`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="roll-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CatalogRoll>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6} style={{ marginTop: -8 }}>
                    <Controller
                      name="Diameter"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Diameter"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            autoComplete="off"
                            value={value}
                            onChange={onChange}
                            disabled={modal === "delete"}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="DonutPartId"
                      control={control}
                      render={({ field }) => (
                        <DonutSelector
                          label="Donut"
                          onChange={field.onChange}
                          value={field.value}
                          disabled={modal === "delete" || rollTypeCode === "E"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RollStatusCode"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          id="clear-on-escape"
                          clearOnEscape
                          options={optionsStatusCode}
                          getOptionLabel={(option) => option.Name}
                          disabled={modal === "delete"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Status"
                              variant="outlined"
                              fullWidth
                              size="small"
                            />
                          )}
                          value={
                            optionsStatusCode.find(
                              (option) =>
                                option.Name.at(0) === field?.value?.at(0)
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            field.onChange(
                              newValue ? newValue.Name.at(0) : null
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RollTypeCode"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          id="clear-on-escape"
                          clearOnEscape
                          options={optionsTypeCode}
                          getOptionLabel={(option) => option.Name}
                          disabled={modal === "delete"}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Type"
                              variant="outlined"
                              fullWidth
                              size="small"
                            />
                          )}
                          value={
                            optionsTypeCode.find(
                              (option) =>
                                option.Name.at(0) === field?.value?.at(0)
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            field.onChange(
                              newValue ? newValue.Name.at(0) : null
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Roll"
      />
    </>
  );
};
