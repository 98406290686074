import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { get } from "lodash-es";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { CartridgeSelector } from "../../../../../../shared/selectors";
import { ChockSelector } from "../../../../../../shared/selectors/ChockSelector";
import { GuideSelector } from "../../../../../../shared/selectors/GuideSelector";
import { LocationSelector } from "../../../../../../shared/selectors/LocationSelector";
import { RollSelector } from "../../../../../../shared/selectors/RollSelector";
import { RollsetSelector } from "../../../../../../shared/selectors/RollsetSelector";
import { SectionSelector } from "../../../../../../shared/selectors/SectionSelector";
import {
  CATALOG_CARRIER_INITIAL_VALUES,
  CatalogCarrier,
} from "../../../../models/parts/CatalogCarrier";
import {
  deleteCarrier,
  getCarriers,
  upsertCarrier,
} from "../../../../repositories/parts/CatalogCarrierRepository";
import { PartFields } from "../../PartFields";

type Props = {
  CarrierId: number | null;
  modal: "create" | "update" | "delete" | "";
  onHide: (shouldUpdate: boolean) => void;
};
export const UpsertDeleteModal = ({ CarrierId, modal, onHide }: Props) => {
  const [title, setTitle] = useState<string>("CREATE");

  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const succesfullyMessage = `The carrier was ${modal}d succesfully`;

  const { control, setValue, reset, handleSubmit } = useForm<CatalogCarrier>({
    defaultValues: CATALOG_CARRIER_INITIAL_VALUES,
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<CatalogCarrier> = async (data) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const carrierResp = await deleteCarrier(data.PartId);
      if (carrierResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(carrierResp.message);
      }
    } else {
      const carrierResp = await upsertCarrier(data);
      if (carrierResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(carrierResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (modal !== "") {
      setIsSubmitLoading(false);
      reset();
      setTitle(
        modal === "create" ? "CREATE" : modal === "update" ? "EDIT" : "DELETE"
      );
      if (modal !== "create")
        (async () => {
          setIsLoading(true);
          const response = await getCarriers(CarrierId);
          if (response.ok) {
            const carrier = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogCarrier;
            if (carrier) {
              setValue("PartId", carrier?.PartId);
              setValue("PartName", carrier?.PartName);
              setValue("RFIDTag", carrier?.RFIDTag);
              setValue("LocationName", carrier?.LocationName);
              setValue("RollsetId", carrier?.RollsetId);
              setValue("UpperRollId", carrier?.UpperRollId);
              setValue("LowerRollId", carrier?.LowerRollId);
              setValue("UMChockId", carrier?.UMChockId);
              setValue("LMChockId", carrier?.LMChockId);
              setValue("UOChockId", carrier?.UOChockId);
              setValue("LOChockId", carrier?.LOChockId);
              setValue("EntryGuideId", carrier?.EntryGuideId);
              setValue("ExitGuideId", carrier?.ExitGuideId);
              setValue("CartridgeId", carrier?.CartridgeId);
              setValue("Bait", carrier?.Bait);
              setValue("WeighUp", carrier?.WeighUp);
              setValue("ScheduledSectionId", carrier?.ScheduledSectionId);
              setValue("ScheduledLocationId", carrier?.ScheduledLocationId);
            }
          } else {
            setError(response.message!);
            return;
          }
          setIsLoading(false);
        })();
    }
  }, [modal]);

  return (
    <>
      <MesfModal
        title={`${title} CARRIER`}
        open={modal !== ""}
        handleClose={() => onHide(false)}
        maxWidth="sm"
        id="carrier-modal"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <PartFields<CatalogCarrier>
                    control={control}
                    disabled={modal === "delete"}
                  />
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="Bait"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          label="Bait"
                          variant="outlined"
                          fullWidth
                          size="small"
                          autoComplete="off"
                          value={field.value}
                          disabled={modal === "delete"}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="WeighUp"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Weigh Up"
                            variant="outlined"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            value={field.value}
                            disabled={modal === "delete"}
                            onChange={field.onChange}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="CartridgeId"
                      control={control}
                      render={({ field }) => (
                        <CartridgeSelector
                          label="Cartridge"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="RollsetId"
                      control={control}
                      render={({ field }) => (
                        <RollsetSelector
                          label="Rollset"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="UpperRollId"
                      control={control}
                      render={({ field }) => (
                        <RollSelector
                          label="Upper Roll"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="LowerRollId"
                      control={control}
                      render={({ field }) => (
                        <RollSelector
                          label="Lower Roll"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="UMChockId"
                      control={control}
                      render={({ field }) => (
                        <ChockSelector
                          label="UM Chock"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="LMChockId"
                      control={control}
                      render={({ field }) => (
                        <ChockSelector
                          label="LM Chock"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="UOChockId"
                      control={control}
                      render={({ field }) => (
                        <ChockSelector
                          label="UO Chock"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="LOChockId"
                      control={control}
                      render={({ field }) => (
                        <ChockSelector
                          label="LO Chock"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="EntryGuideId"
                      control={control}
                      render={({ field }) => (
                        <GuideSelector
                          label="Entry Guide"
                          typeCode="I"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="ExitGuideId"
                      control={control}
                      render={({ field }) => (
                        <GuideSelector
                          label="Exit Guide"
                          typeCode="O"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="ScheduledSectionId"
                      control={control}
                      render={({ field }) => (
                        <SectionSelector
                          label="Scheduled Section"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="ScheduledLocationId"
                      control={control}
                      render={({ field }) => (
                        <LocationSelector
                          label="Scheduled Location"
                          value={field.value}
                          onChange={field.onChange}
                          disabled={modal === "delete"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
              buttonLabel={modal === "delete" ? "Delete" : "Save"}
              buttonColor={modal === "delete" ? "secondary" : "primary"}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Carrier"
      />
    </>
  );
};
