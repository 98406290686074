import { Button, IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { UseMutationResult } from "@tanstack/react-query";
import {
  ColGroupDef,
  ICellRendererParams,
  StatusPanelDef,
} from "ag-grid-community";
import { useMemo } from "react";
import { BarrelPassShape } from "../../../../../models/BarrelPassShape";
import { CatalogBarrel } from "../../../../../models/CatalogBarrel";

const useStyles = makeStyles(() => ({
  buttons: {
    opacity: 0,
    transition: "opacity 0.1s ease-in-out",
    ".ag-row-hover &": {
      opacity: 1,
    },
    ".ag-row-focus &": {
      opacity: 1,
    },
    ".ag-row-selected &": {
      opacity: 1,
    },
  },
  header: {
    "& .ag-header-group-cell-label ": {
      textAlign: "center !important",
      justifyContent: "center",
    },
    "& .ag-header-group-text ": {
      fontSize: 14,
    },
  },
}));

const CustomStatusBar = ({ showPassShapesModal }: any) => (
  <div
    style={{
      position: "relative",
      height: "30px",
      display: "flex",
      alignItems: "center",
      marginLeft: -24,
    }}
  >
    <IconButton
      onClick={showPassShapesModal}
      disabled={showPassShapesModal ? false : true}
      color="primary"
      title="Set pass shapes"
    >
      <PlaylistAddIcon style={{ width: 30, height: 30 }} />
    </IconButton>
  </div>
);

type Props = {
  mutation: UseMutationResult<void, Error, BarrelPassShape, unknown>;
  barrel: CatalogBarrel | null;
  setShow: (show: boolean) => void;
};

export const useBarrelPassShapeData = ({
  mutation,
  barrel,
  setShow,
}: Props) => {
  const classes = useStyles();

  const columnDefs: ColGroupDef[] = [
    {
      headerName: "Barrel Pass Shapes",
      headerClass: classes.header,
      children: [
        {
          field: "Position",
          headerName: "Position",
          flex: 1,
        },
        {
          field: "PassShapeName",
          headerName: "Pass Shapes",
          flex: 2,
        },
        {
          field: "PassShapeType",
          headerName: "Type",
          flex: 2,
        },
        {
          field: "Shape",
          headerName: "Shape",
          flex: 2,
        },
        {
          cellRenderer: (params: ICellRendererParams) => (
            <div
              className={classes.buttons}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => mutation.mutate(params.data as BarrelPassShape)}
                title="Delete"
              >
                <DeleteIcon style={{ height: "auto" }} color="secondary" />
              </Button>
            </div>
          ),
          flex: 1,
        },
      ],
    },
  ];

  const statusBar = useMemo<{
    statusPanels: StatusPanelDef[];
  }>(() => {
    return {
      statusPanels: [
        {
          key: "customStatusBar",
          statusPanel: CustomStatusBar,
          statusPanelParams: {
            showPassShapesModal: barrel ? () => setShow(true) : null,
          },
          align: "left",
        },
      ],
    };
  }, []);

  return { columnDefs, statusBar };
};
