import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Roll } from "../models/Roll";

export const getRolls = async (partId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (partId) parameters.push({ name: "PartId", value: partId });

  const resp = await apiService.callV2("[M2].[GetRolls]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Roll[];
  } else {
    throw new Error(resp.message || "Error fetching rolls");
  }
};
