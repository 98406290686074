import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogSection } from "../models/CatalogSection";

export const getSections = async (sectionId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (sectionId)
		parameters.push({ name: "SectionId", value: sectionId });

	return await apiService.callV2("[M2].[GetSections]", parameters);
};
export const upsertSection = async (section: CatalogSection) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: section.SectionId },
		{ name: "SectionName", value: section.SectionName }
	];

	return await apiService.callV2("[M2].[UpsertSection]", parameters);
};

export const deleteSection = async (sectionId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "SectionId", value: sectionId }
	];

	return await apiService.callV2("[M2].[DeleteSection]", parameters);
};