import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Location } from "../models/Location";

export const getLocations = async (locationId?: number | null) => {
  const apiService = new MESApiService();

  const parameters: spParameter[] = [];

  if (locationId) parameters.push({ name: "LocationId", value: locationId });

  const resp = await apiService.callV2("[M2].[GetLocations]", parameters);

  if (resp.ok) {
    return get(resp, "data.tables[0].rows", []) as Location[];
  } else {
    throw new Error(resp.message || "Error fetching locations");
  }
};
