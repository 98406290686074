import { ErrorModal } from "@dexteel/mesf-core";
import { CircularProgress, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

type Props = {
  label: string;
  value: number | null;
  onChange: Function;
  disabled: boolean;
};

// Rollset not yet implemented
/* const useRollsets = (partId?: number, onError?: (error: Error) => void) => {
  return useQuery<CatalogRollset[], Error>({
    queryKey: ["rollsets", partId],
    queryFn: () => getRollsetsV2(partId),
    onError: onError,
  });
}; */

export const RollsetSelector = ({ label, value, onChange, disabled }: Props) => {
  const [error, setError] = useState("");

  /* const { data: rollsets, isLoading } = useRollsets(undefined, (error) =>
    setError(error.message)
  ); */

  const selectedValue = null

  return (
    <>
      <Autocomplete
        id="clear-on-escape"
        clearOnEscape
        options={[]}
        getOptionLabel={(option) => option}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            size="small"
            InputProps={{
              ...params.InputProps,
              /* endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ), */
            }}
          />
        )}
        value={null}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error loading rollsets"
      />
    </>
  );
};
