import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { Application } from "../models/Application";

export const getApplications = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	const resp = await apiService.callV2("[M2].[GetApplicationsByPart]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as Application[];
	}
	else {
		throw new Error(resp.message || "Error fetching applications");
	}
};

export const getApplicationsByPart = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [{ name: "PartId", value: partId }];

	return await apiService.callV2("[M2].[GetApplicationsByPart]", parameters);
};

export const setApplicationsToPart = async (partId: number, applications: Application[]) => {
	const apiService = new MESApiService();

	const applicationsIds = applications.map((item) => item.ApplicationId);
	const applicationIdsString = applicationsIds.join(",");

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId },
		{ name: "ApplicationIds", value: applicationIdsString }
	];

	return await apiService.callV2("[M2].[SetApplicationsToPart]", parameters);
};