import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogCarrier } from "../../models/parts/CatalogCarrier";

export const getCarriers = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetCarriers]", parameters);
};
export const upsertCarrier = async (carrier: CatalogCarrier) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: carrier.PartId },
		{ name: "PartName", value: carrier.PartName },
		{ name: "RollsetId", value: carrier.RollsetId },
		{ name: "UpperRollId", value: carrier.UpperRollId },
		{ name: "LowerRollId", value: carrier.LowerRollId },
		{ name: "UMChockId", value: carrier.UMChockId },
		{ name: "LMChockId", value: carrier.LMChockId },
		{ name: "UOChockId", value: carrier.UOChockId },
		{ name: "LOChockId", value: carrier.LOChockId },
		{ name: "EntryGuideId", value: carrier.EntryGuideId },
		{ name: "ExitGuideId", value: carrier.ExitGuideId },
		{ name: "CartridgeId", value: carrier.CartridgeId },
		{ name: "Bait", value: carrier.Bait },
		{ name: "WeighUp", value: carrier.WeighUp },
		{ name: "ScheduledSectionId", value: carrier.ScheduledSectionId },
		{ name: "ScheduledLocationId", value: carrier.ScheduledLocationId },
	];

	return await apiService.callV2("[M2].[UpsertCarrier]", parameters);
};

export const deleteCarrier = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteCarrier]", parameters);
};