import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { get } from "lodash-es";
import { BarrelPassShape, SelectedPassShapes } from "../models/BarrelPassShape";

export const getBarrelPassShapes = async (passShapeId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (passShapeId)
		parameters.push({ name: "PassShapeId", value: passShapeId });

	const resp = await apiService.callV2("[M2].[GetBarrelPassShapes]", parameters);

	if (resp.ok){
		return get(resp, "data.tables[0].rows", []) as BarrelPassShape[];
	}
	else {
		throw new Error(resp.message || "Error fetching barrel pass shapes");
	}
};

export const getPassShapesByBarrel = async (barrelId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (barrelId)
		parameters.push({ name: "BarrelId", value: barrelId });

	return await apiService.callV2("[M2].[GetPassShapesByBarrel]", parameters);
};

export const deleteBarrelPassShape = async (barrelPassShape: BarrelPassShape) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelPassShape.BarrelId },
		{ name: "Position", value: barrelPassShape.Position }
	];

	const resp = await apiService.callV2("[M2].[DeleteBarrelPassShape]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting barrel pass shape");
	}
};

export const setBarrelPassShapes = async (barrelId: number, passShapesInfo: SelectedPassShapes[]) => {
	const apiService = new MESApiService();

	const passShapesInfoString = passShapesInfo.map((item, index) => 
		`${index + 1},${item.id}`
	).join(';');

	const parameters: spParameter[] = [
		{ name: "BarrelId", value: barrelId },
		{ name: "PassShapeInfo", value: passShapesInfoString },
	];

	const resp = await apiService.callV2("[M2].[SetBarrelPassShapes]", parameters);

	if (!resp.ok){
		throw new Error(resp.message || "Error deleting barrel pass shape");
	}
};

