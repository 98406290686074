import { useContextMenuMESF } from "@dexteel/mesf-core";
import { Grid, IconButton, Typography } from "@material-ui/core";
import {
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  RowSelectedEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";

import { useGridDefinitions } from "../../../../../controls/ag-grid/components/GridDefinitions";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";

import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { CatalogBarrel } from "../../../models/CatalogBarrel";
import { useGridStyles } from "../../../styles/gridStyles";
import { useBarrelOptionsFunctions } from "../hooks/useBarrelOptionsFunctions";
import { useTableData } from "../hooks/useTableData";
import { BarrelDetails } from "./BarrelDetails";

type Props = {
  getCatalogBarrelsFromAPI: Function;
  isLoading: boolean;
};
export const TableBarrel = ({ getCatalogBarrelsFromAPI, isLoading }: Props) => {
  const classes = useGridStyles();

  const [selectedRowData, setSelectedRowData] = useState<CatalogBarrel | null>(
    null
  );

  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [showModal, setShowModal] = useState<
    "create" | "update" | "delete" | ""
  >("");

  const { showContextMenu, registerConfig } = useContextMenuMESF();
  const { getMenuOptions } = useBarrelOptionsFunctions({
    setSelectedRowData,
    setShowModal,
  });
  const { rows, columnDefs } = useTableData({
    setSelectedRowData,
    setShowModal,
    showContextMenu,
  });

  const { columnDefaults } = useGridDefinitions({
    OnEdit: (data: any) => {
      setShowModal("update");
      setSelectedRowData(data);
    },
  });

  const onRowSelected = (event: RowSelectedEvent) => {
    if (event.node.isSelected()) {
      setSelectedRowData(event.data);
      setShowModal("update");
    }
  };

  const getContextMenuItems = React.useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const data = params.node?.data;
      showContextMenu(event as any, data, "barrelContext");
      if (data) {
        params.api.deselectAll();
        params.node?.setSelected(true);
      }
      return [];
    },
    [rows]
  );

  const onModalHide = (shouldUpdate: boolean) => {
    if (shouldUpdate)
      getCatalogBarrelsFromAPI().then(() => {
        gridApi?.paginationGoToLastPage();
      });
    if (gridApi)
      gridApi.deselectAll();
    setShowModal("");
    setSelectedRowData(null);
  };

  const handleNewBarrel = () => {
    if (gridApi) gridApi.deselectAll();
    setShowModal("create");
    setSelectedRowData(null);
  };

  useEffect(() => {
    if (isLoading) {
      gridApi?.showLoadingOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  }, [isLoading, rows, gridApi]);

  useEffect(() => {
    registerConfig({
      id: "barrelContext",
      getOptions: getMenuOptions,
    });
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item md={12} xs={12} style={{ padding: "0 15px" }}>
        <Typography
          variant="h5"
          style={{ margin: "1px 0 10px", fontWeight: 600 }}
        >
          Barrels
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}
        style={{ width: "100%", padding: "0 10px" }}
        spacing={2}
      >
        <Grid item md={6} xs={12}>
          <div
            style={{ height: "67vh", width: "100%" }}
            className={`${classes.table} ag-theme-alpine`}
          >
            <AgGridReact<CatalogBarrel>
              rowData={rows || []}
              columnDefs={columnDefs}
              defaultColDef={columnDefaults}
              rowHeight={34}
              headerHeight={34}
              animateRows
              pagination
              rowSelection="single"
              paginationAutoPageSize={true}
              getContextMenuItems={getContextMenuItems}
              onGridReady={(params) => setGridApi(params.api)}
              getRowId={(params) =>
                String((params.data as CatalogBarrel).BarrelId)
              }
              loadingOverlayComponent={CenteredLazyLoading}
              onRowSelected={onRowSelected}
            />
            <IconButton
              onClick={handleNewBarrel}
              color="primary"
              style={{ position: "absolute", padding: 0, bottom: 53, left: 63 }}
            >
              <PlaylistAddIcon style={{ width: 30, height: 30 }} />
            </IconButton>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <div
            style={{ height: "67vh", width: "100%" }}
            className={`${classes.table} ag-theme-alpine`}
          >
            <BarrelDetails
              barrel={selectedRowData}
              onHide={onModalHide}
              modal={showModal}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
