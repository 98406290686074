import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../../controls/buttons/FooterModalButtons";
import { NotificationSnackBar } from "../../../../../../controls/snackbars/notification-snackbar";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CATALOG_PASS_SHAPE_INITIAL_VALUES,
  CatalogPassShape,
} from "../../../../models/CatalogPassShape";
import { upsertPassShape } from "../../../../repositories/CatalogPassShapeRepository";

type Props = {
  show: boolean;
  onHide: () => void;
};
export const CreatePassShape = ({ show, onHide }: Props) => {
  const [message, setNotificationMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const { control, reset, handleSubmit } = useForm<CatalogPassShape>({
    defaultValues: CATALOG_PASS_SHAPE_INITIAL_VALUES,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(upsertPassShape, {
    onSuccess: () => {
      queryClient.invalidateQueries(["passShapes"]);
      setNotificationMessage("The pass shape was created succesfully");
      onHide();
    },
    onError: (error: Error) => {
      setError(error.message);
    },
    onSettled: () => {
      setIsSubmitLoading(false);
    },
  });

  const onSubmit: SubmitHandler<CatalogPassShape> = async (data) => {
    setIsSubmitLoading(true);
    mutation.mutate(data);
  };

  useEffect(() => {
    if (show) {
      setIsSubmitLoading(false);
      reset();
    }
  }, [show]);

  return (
    <>
      <MesfModal
        title="CREATE PASS SHAPE"
        open={show}
        handleClose={() => onHide()}
        maxWidth="sm"
        id="pass-shape-modal"
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSubmit(onSubmit)(e);
          }}
        >
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  name="PassShapeName"
                  control={control}
                  rules={{ required: "Name is required" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl error={!!error} fullWidth>
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        autoComplete="off"
                        value={value}
                        onChange={onChange}
                      />
                      {error && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </MesfModal.Content>
          <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
            <FooterModalButtons
              isSubmitLoading={isSubmitLoading}
              onHide={onHide}
            />
          </MesfModal.Actions>
        </form>
      </MesfModal>
      <NotificationSnackBar
        message={message}
        onHide={() => setNotificationMessage("")}
      />
      <ErrorModal
        error={error}
        onHide={() => setError("")}
        title="Error In Pass Shape"
      />
    </>
  );
};
