import { ErrorModal, MesfModal } from "@dexteel/mesf-core";
import {
  Checkbox,
  DialogProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { FooterModalButtons } from "../../../../../controls/buttons/FooterModalButtons";
import { CenteredLazyLoading } from "../../../../../controls/LazyLoading";
import { NotificationSnackBar } from "../../../../../controls/snackbars/notification-snackbar";

import { get } from "lodash-es";
import { LocationSelector } from "../../../../../shared/selectors";
import {
  CATALOG_LOCATION_INITIAL_VALUES,
  CatalogLocation,
} from "../../../models/CatalogLocation";
import {
  deleteLocation,
  getLocations,
  upsertLocation,
} from "../../../repositories/CatalogLocationRepository";
import { useCatalogLocationContext } from "../context/CatalogLocationContext";

type Props = {
  LocationId: number | null | undefined;
  show: boolean;
  modal: "create" | "update" | "delete" | "view" | "";
  onHide: (shouldUpdate: boolean) => void;
};

export const UpsertDeleteViewLocation = ({
  LocationId,
  show,
  modal,
  onHide,
}: Props) => {
  const maxWidth: DialogProps["maxWidth"] = "sm";
  const [error, setError] = useState<string>("");
  const [title, setTitle] = useState<string>("CREATE");
  const [notificationMessage, setNotificationMessage] = useState<string>("");
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const succesfullyMessage = `The location was ${modal}d succesfully`;
  const optionsTypeCode = [{ Name: "Mill" }, { Name: "Warehouse" }];

  const {
    state: { allLocations, locationNodeSelectedInTree },
    actions: {
      setLocationParentIdToCreateLocation,
      setLocationNameToCreateLocation,
    },
  } = useCatalogLocationContext();

  const { control, handleSubmit, reset, setValue } = useForm<CatalogLocation>({
    defaultValues: CATALOG_LOCATION_INITIAL_VALUES,
  });

  const handleCancel = () => {
    onHide(false);
  };

  const onSubmit: SubmitHandler<CatalogLocation> = async (data: any) => {
    setIsSubmitLoading(true);
    if (modal === "delete") {
      const locationResp = await deleteLocation(data.LocationId);
      if (locationResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(locationResp.message);
      }
    } else {
      const locationResp = await upsertLocation(data);
      if (locationResp.ok) {
        setNotificationMessage(succesfullyMessage);
        onHide(true);
      } else {
        setError(locationResp.message);
      }
    }
    setIsSubmitLoading(false);
  };

  useEffect(() => {
    if (show) {
      reset();
      setTitle(
        modal === "create"
          ? "CREATE"
          : modal === "update"
          ? "EDIT"
          : modal === "delete"
          ? "DELETE"
          : "VIEW"
      );
      if (modal !== "create") {
        (async () => {
          setIsLoading(true);
          const response = await getLocations(LocationId);
          if (response.ok) {
            const location = get(
              response,
              "data.tables[0].rows[0]",
              null
            ) as CatalogLocation;
            if (location) {
              setValue("ParentLocationId", location.ParentLocationId);
              setValue("LocationId", location?.LocationId);
              setValue("LocationName", location?.LocationName);
              setValue("LocationTypeCode", location.LocationTypeCode);
              setValue("IsExclusive", location?.IsExclusive);
            }
          } else {
            setError(response.message);
            return;
          }
          setIsLoading(false);
        })();
      } else {
        const parentRoot: CatalogLocation = allLocations.find(
          (node: CatalogLocation) => node.ParentLocationId === null
        );
        if (parentRoot) {
          setLocationParentIdToCreateLocation(parentRoot.LocationId);
          setValue("ParentLocationId", parentRoot.LocationId);
        }
        setLocationNameToCreateLocation("");
      }
    }
  }, [show]);

  return (
    <>
      <MesfModal
        title={`${title} LOCATION`}
        maxWidth={maxWidth}
        open={show}
        handleClose={() => handleCancel()}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <MesfModal.Content dividers style={{ padding: "10px 30px" }}>
            {isLoading && <CenteredLazyLoading />}
            {!isLoading && (
              <>
                <Grid container spacing={1}>
                  <Grid item md={3} sm={12} xs={10}>
                    <Controller
                      name="LocationTypeCode"
                      control={control}
                      render={({ field }) => (
                        <>
                          <TextField
                            label="Type Code"
                            variant="outlined"
                            value={
                              optionsTypeCode.find(
                                (code) => code.Name.at(0) === field.value
                              )?.Name
                            }
                            disabled
                            margin="dense"
                            size="small"
                            fullWidth
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item md={9} sm={12} xs={10}>
                    <Controller
                      name="LocationName"
                      control={control}
                      rules={{ required: "Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormControl error={!!error} fullWidth>
                          <TextField
                            label="Location Name"
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            size="small"
                            autoComplete="off"
                            onChange={onChange}
                            value={value}
                            disabled={modal === "delete" || modal === "view"}
                          />
                          {error && (
                            <FormHelperText>{error.message}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                {(locationNodeSelectedInTree.ParentLocationId ||
                  modal === "create") && (
                  <Grid container>
                    <Grid item md={12} sm={12} xs={10}>
                      <Controller
                        name="ParentLocationId"
                        control={control}
                        rules={{ required: "Parent is required" }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl error={!!error} fullWidth>
                            <LocationSelector
                              label="Parent"
                              value={field.value}
                              onChange={field.onChange}
                              disabled={modal === "delete" || modal === "view"}
                            />
                            {error && (
                              <FormHelperText>{error.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container>
                  <Grid item md={12} sm={12} xs={12}>
                    <Controller
                      name="IsExclusive"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={field.value}
                              onChange={field.onChange}
                              name="IsCircular"
                              color="primary"
                              disabled={modal === "delete" || modal === "view"}
                            />
                          }
                          label="Is Exclusive"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </MesfModal.Content>
          {modal !== "view" && (
            <MesfModal.Actions style={{ padding: "20px 30px 30px" }}>
              <FooterModalButtons
                isSubmitLoading={isSubmitLoading}
                onHide={handleCancel}
                buttonLabel={modal === "delete" ? "Delete" : "Save"}
                buttonColor={modal === "delete" ? "secondary" : "primary"}
              />
            </MesfModal.Actions>
          )}
        </form>
      </MesfModal>
      <ErrorModal error={error} onHide={() => setError("")} />
      <NotificationSnackBar
        message={notificationMessage}
        onHide={() => setNotificationMessage("")}
      />
    </>
  );
};
