import { MESApiService, spParameter } from "@dexteel/mesf-core";
import { CatalogGuide } from "../../models/parts/CatalogGuide";

export const getGuides = async (partId?: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [];

	if (partId)
		parameters.push({ name: "PartId", value: partId });

	return await apiService.callV2("[M2].[GetGuides]", parameters);
};
export const upsertGuide = async (chock: CatalogGuide) => {
	const apiService = new MESApiService();
	
	const parameters: spParameter[] = [
		{ name: "PartId", value: chock.PartId },
		{ name: "PartName", value: chock.PartName },
	];

	if (chock.GuideTypeCode)
		parameters.push({ name: "GuideTypeCode", value: chock.GuideTypeCode})

	return await apiService.callV2("[M2].[UpsertGuide]", parameters);
};

export const deleteGuide = async (partId: number | null) => {
	const apiService = new MESApiService();

	const parameters: spParameter[] = [
		{ name: "PartId", value: partId }
	];

	return await apiService.callV2("[M2].[DeleteGuide]", parameters);
};