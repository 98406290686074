export interface CatalogBarrel {
	BarrelId: number | null;
	BarrelName: string;
	Comments: string;
	IsActive: boolean | null;
}

export const CATALOG_BARREL_INITIAL_VALUES: CatalogBarrel = {
	BarrelId: null,
	BarrelName: '',
	Comments: '',
	IsActive: true
};